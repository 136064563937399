@import "../../style/variable.scss";

.button {
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  cursor: pointer;

  &.primary {
    background: $corPrimaria;
    border: 1px solid $corPrimaria;
    &:enabled:hover {
      color: #ffffff;
      border-color: $corPrimariaHover;
      background: $corPrimariaHover;
    }

    &:enabled:active {
      border-color: $corPrimariaActive;
      background: $corPrimariaActive;
      color: #ffffff;
    }
  }

  &.danger {
    background: $corErro;
    border: 1px solid $corErro;
    &:enabled:hover{
      background: $corErroHover;
      color: #ffffff;
      border-color: $corErroHover;
    }

    &:enabled:active{
      background: $corErroActive;
      color: #ffffff;
      border-color: $corErroActive;
    }
  }

  &.secondary {
    background: $corSecundaria;
    border: 1px solid $corSecundaria;
    &:enabled:hover{
      background: $corSecundariaHover;
      color: #ffffff;
      border-color: $corSecundariaHover;
    }

    &:enabled:active{
      background: $corSecundariaActive;
      color: #ffffff;
      border-color: $corSecundariaActive;
    }
  }


  &.transparent {
    font-weight: bold;
    color: $corPrimaria;
    background: transparent;
    border: 1px solid transparent;
    &:enabled:hover{
      background: rgba(96, 125, 139, 0.14);
      color: $corPrimariaHover;
      border-color: transparent;
    }

    &:enabled:active{
      background: rgba(96, 125, 139, 0.14);
      color: $corPrimariaActive;
      border-color: transparent;
    }
  }



  &:disabled {
    opacity: 0.6;
  }

  &.border {
    border-radius: 0px 8px 8px 0px;
  }

}

.button-max-with{
  >button {
    width: 100%;
  }
}