$corPrimaria: #2196F3;
$corPrimariaShadow: #a6d5fa;
$corPrimariaHover: #0d89ec;
$corPrimariaActive: #0b7ad1;


$corSecundaria:#689F38;
$corSecundariaHover: #5e8f32;
$corSecundariaActive:#537f2d;
$corSecundariaShadow: #c2e0a8;

$corBlockUi: #00000049;
$corBlockModal: #000000a8;

//$corBlockUi: #00000000;

$corFundo: #00000049;

$corErro: #f44336;
$corErroHover: #f44336;
$corErroActive: #f72112;
$corErroShadow: #f4433685;


$corSucesso: #b3e5fc;
$corFalha: #f4433685;

$corCabecalhoRodape: #b3b3b3;
$corCabecalhoRodapeShadow: #ccc;



$corFontMsg: #044868;
$corFontMsgErro: rgb(115, 0, 12);
$corBordaEsquerdaSucesso: #0891cf;
$corMsgFalha: #f58b83;
$corMsgFalha: #FFCDD2;

$corBordaEsquerdaAviso: #d9a300;
$corFontAviso: #775a04;
$corMsgAviso: #ffecb3;




$corAlternativo: #607d8b;
$corAlternativoHover: #526975;
$corAlternativoActive: #435761;
$corAlternativoShadow: #607d8b79;

$blurValue: 1px;

$fontColor: #495057
