@import "../../style/variable.scss";
.inputtext {
  font-size: 1rem;
  //color: #495057;
  color: $fontColor;
  background: transparent;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;

  &:enabled:hover {
    border-color: $corPrimaria;
  }

  &:enabled:focus {
    border-color: $corPrimaria;
  }

  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    //   box-shadow: 0 0 0 0.2rem $corPrimariaShadow;
    //   border-color: $corPrimaria;

    border-color: $corPrimaria;
    box-shadow: 0 0 0 0.2rem $corPrimariaShadow;
  }

  &.error {
    border-color: $corErro;
    color: $corErro;
    font-weight: 700;
  }

  &.error:enabled:focus {
    border-color: $corErro;
  }


  &.error:enabled:hover {
    border-color: $corErro;
  }

  &.error:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $corErroShadow;
    border-color: $corErro;
  }

  &.error ~ span {
    display: block;
    color: $corErro;
  }

  &.error::placeholder {
    color: $corErro;
  }
}
