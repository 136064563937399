.area{
  max-width: 600px;
  margin: 25px auto 0;
}

.logo{
  max-width: 100%;
}

.base-area{
  span {
    display: block;
  }
}
