.notFoundImgArea {
  max-width: 800px;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  margin: 0 auto;

  img {
    max-width: 800px;
    width: 100%;
  }
}