@import "../node_modules/flexboxgrid/dist/flexboxgrid.css";
@import "./style/font/font.css";

html, body {
  font-family: 'Atkinson Hyperlegible', sans-serif !important;
}

body {
  margin: 5px 5px 5px 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: inherit;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
  font-family: 'Atkinson Hyperlegible', sans-serif !important;
}

.input-area {
  & > label {
    /*font-weight: bold;*/

    &.error {
      color: red;
    }
  }

  & small.error {
    /*font-weight: bold;*/
    color: red;
  }

  & input {
    margin-top: 15px;
  }

  padding-bottom: 20px;
}
