@import "../../style/variable.scss";

.message-alert {

  border-radius: 8px;
  height: 50px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;

  &.info {
    border-left: 8px solid $corBordaEsquerdaSucesso;
    background-color: $corSucesso;
    color: $corFontMsg;

    button {
      color: $corFontMsg;
    }
  }

  &.error {
    background-color: $corMsgFalha;
    border-left: 8px solid red;
    color: $corFontMsgErro;

    button {
      color: $corFontMsgErro;
    }

  }

  &.warn {
    background-color: $corMsgAviso;
    border-left: 8px solid $corBordaEsquerdaAviso;
    color: $corFontAviso;

    button {
      color: $corFontAviso;
    }
  }

  button {
    float: right;
    background: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    cursor: pointer;
  }

  span {
    float: left;
    font-weight: 500;
    padding-left: 8px;
  }

  img {
    float: left;
    width: 30px;
    margin-top: -5px;
  }
}