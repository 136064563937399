@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 300;
    font-style: normal;
    src: url('AtkinsonHyperlegible-Regular.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 300;
    font-style: italic;
    src: url('AtkinsonHyperlegible-Italic.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: normal;
    font-style: normal;
    src: url('AtkinsonHyperlegible-Regular.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: normal;
    font-style: italic;
    src: url('AtkinsonHyperlegible-Italic.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 400;
    font-style: normal;
    src: url('AtkinsonHyperlegible-Regular.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 700;
    font-style: normal;
    src: url('AtkinsonHyperlegible-Regular.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 400;
    font-style: italic;
    src: url('AtkinsonHyperlegible-BoldItalic.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 600;
    font-style: normal;
    src: url('AtkinsonHyperlegible-Bold.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: 600;
    font-style: italic;
    src: url('AtkinsonHyperlegible-BoldItalic.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: bold;
    font-style: normal;
    src: url('AtkinsonHyperlegible-Bold.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: bold;
    font-style: italic;
    src: url('AtkinsonHyperlegible-BoldItalic.ttf');
}