.blockui-area{
  top: 0;
  width: 100%;
  height: 100vh;
  //height: 100%;
  position: fixed;
  box-sizing: border-box;
  z-index: 999;
  left: 0;

  background-color: rgba(96, 125, 139, 0.00);
  img {
    float: right;
    width: 100px;
    margin-right: 50px;
    margin-top: 50px;
  }
}

