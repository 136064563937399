.search-area{
  & .left{
    float: left;
    height: 80px;
    width: 50%;
  }
  & .right{
    float: right;
    width: 50%;
  }
  & .end{
    clear: both;
  }

  .area-btn {
    height: 80px;
    display:         flex;
    display: -webkit-flex; /* Garante compatibilidade com navegador Safari. */
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .name-social-reason{
    font-weight: bold; margin-bottom: 15px; margin-top: 15px;
  }
}

.not-found{
  padding-top: 10px;
  padding-bottom: 10px;
}
