.paginator-area{
  display: flex;
  align-items: center;
  justify-content: center;
  & button{
    margin-left: 1px;
    margin-right: 1px;
  }
}

